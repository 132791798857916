<template>
  <div class="m_login_content">
    <div class="m_login_main">
      <div class="m_login_main_top">售货机管理系统</div>
      <div class="m_login_boittom clearfix">
        <div class="fl m_login_main_bottom_left">
          <h1></h1>
        </div>
        <div class="fl m_login_main_bottom_right">
          <div class="m_login_form">
            <el-form ref="form" :model="form" label-width="50px" :rules="rules" @keyup.enter.native="loginIn()">
              <el-form-item label="账号" prop="usercode">
                <el-input v-model="form.usercode"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="form.password" type="password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="loginIn"
                  size="mini"
                  class="m_btn_save"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/base";
import { APP_TYPE } from "@/utils/config";
export default {
  directives: {},
  data() {
    return {
      form: {
        usercode: "",
        password: "",
        appId: APP_TYPE,
      },
      rules: {
      	usercode: [
            { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
    };
  },
  created() {
  },
  mounted() {},
  methods: {
		loginIn(){
				 this.$refs.form.validate((valid) => {
		        if (valid) {
		        	this.login();
		        } else {
		          return false;
		        }
		      });
		},
    async login() {
		      const res = await login(this.form);
		      const { pageCount, results } = res;
		      this.$store.commit("user/SET_USERINFO", results[0].userInfo);
		      this.$store.commit("user/SET_TOKEN", results[0].token);
		      this.$router.push("/");
  	},
  },
  beforeDestroy() {},
  destroyed() {
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/login/login.less";
</style>
